<template>
  <div>
    <div class="rounded-xl shadow-xl border w-full bg-white">
      <h1 class="text-left text-gray-500 my-4 ml-4 text-lg font-bold">
        Información básica del informe
      </h1>
      <a-divider class="bg-gray-600 mt-0"></a-divider>
      <div class="px-5 pb-8 flex flex-wrap gap-4 w-full">
        <div class="w-2/3">
          <BaseSelect
            :loading="loading"
            :disabled="lectureMode"
            class=""
            label="Entidad"
            :selectOptions="optionsCompanies"
            v-model="form.company_id"
          />
        </div>
        <BaseTextarea
          :disabled="lectureMode"
          label="Descripción"
          maxlength="4500"
          v-model="form.description"
          :value="form.description"
        ></BaseTextarea>
      </div>
      <div class="flex gap-4 my-6 mx-6 justify-end" >
        <a-button
          type="primary"
          ghost
          shape="round"
          @click="$router.push('./reports')"
        >
          VOLVER</a-button
        ><a-button type="primary" shape="round" @click="lectureMode ? continueReport(): startReport()">
         {{lectureMode ? 'CONTINUAR':'INICIAR INFORME'}} </a-button
        >
      </div>
    
    </div>
  </div>
</template>
<script>
export default {
  props: {
    lectureMode: { type: Boolean, default: false },
  },
  data() {
    return {
      annual_report: "",
      company_type: "",
      form: {
        company_id: null,
        sfc_code: "",
        description: "",
        company_type: "",
      },
      company_id: "",
      loading: false,
    };
  },
  created() {
    this.getReport();
  },
  methods: {
    async getReport(loading = true) {
      this.loading = loading;
      if (this.$route.query?.annual_report) {
        let { error, data } = await this.$api.getStepReport(
          this.$route.query.annual_report,
          0
        );
        if (data) {
          this.form.description = data?.data?.general.description;
          this.form.company_id =
            data?.data?.general?.company.id_company.toString();
          this.loading = false;
        }
      }
      this.loading = false;
    },
    async startReport() {
      if (this.form.company_id !== "" && this.form.description !== "") {
        let company = this.getCompany;
        this.form.company_type = company.company_type;
        this.form.sfc_code = company.sfc_code;
        let { error, data } = await this.$api.createReport(this.form);
        if (error?.code === 500) {
          this.showToast(
            "error",
            `No es posible iniciar el informe. ${error.message}`
          );
        } else if (error)
          this.showToast(
            "error",
            `Ocurrió un error inesperado. ${error.message}`
          );
        if (data?.code === 200) {
          this.$router.push({
            name: "reportFormSteps",
            query: {
              company_id: this.form.company_id,
              annual_report: data?.data?.annual_report,
              company_type: data?.data?.company_type,
            },
          });
        }
      } else
        this.showToast(
          "info",
          "Mensaje",
          "Es necesario que selecione una entidad y agregue una descripción para iniciar el informe."
        );
    },
    continueReport() {
      this.$router.push({
        name: "reportFormSteps",
        query: {
          company_id: this.form.company_id,
          annual_report: this.$route.query.annual_report,
          company_type: this.getCompany.company_type,
        },
      });
    },
    showToast(result = "info", title = "Resultado", msg, btn) {
      const key = `open${Date.now()}`;
      this.$notification[result]({
        message: title,
        description: msg,
        btn: btn
          ? (h) => {
              return h(
                "a-button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  on: {
                    click: () => this.$notification.close(key),
                  },
                },
                "Entendido"
              );
            }
          : null,
        key,
        onClose: close,
        style: {
          width: "500px",
          marginLeft: `${350 - 500}px`,
        },
      });
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    optionsCompanies: function () {
      this.loading = true;
      const companies = this.$store.state.form?.optionsCompanies?.filter(
        (item) => item?.direction == this.$store.state?.session?.user.direction
      );
      this.loading = false;
      return companies;
    },
    getCompany: function () {
      return this.optionsCompanies?.filter(
        (item) => item?.id == this.form.company_id
      )[0];
    },
  },
};
</script>
<style></style>
